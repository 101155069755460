import {
    Dimensions,
    MediaTypes,
    RouteErrors,
    SocialLinks,
    TagCategoriesIds
} from '../models/models';
import { TrekAreas, TrekSegments } from '../../trek/models/trek.model';
import { environment } from '@env';
import { InjectionToken } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { LocaleType } from '../models/locales.models';
// TODO: Old dimensions - to be removed ASAP
export const DIMENS: Dimensions = {
    screen_medium: 992,
    screen_small: 600,
    navbar_height_small: 56,
    navbar_height_large: 70
};
export enum DIMENSIONS {
    SCREEN_1440 = 1440,
    SCREEN_1024 = 1024,
    SCREEN_768 = 768,
    SCREEN_425 = 425,
    SCREEN_375 = 375,
    SCREEN_320 = 320
}

export const IS_SCREEN_1440 = new InjectionToken<boolean>(
    'Is screen bigger than 1440',
    {
        providedIn: 'root',
        factory: () => window.innerWidth >= DIMENSIONS.SCREEN_1440
    }
);
export const IS_SCREEN_1024 = new InjectionToken<boolean>(
    'Is screen smaller than 1440',
    {
        providedIn: 'root',
        factory: () => window.innerWidth < DIMENSIONS.SCREEN_1440
    }
);
export const IS_SCREEN_768 = new InjectionToken<boolean>(
    'Is screen smaller than 1024',
    {
        providedIn: 'root',
        factory: () => window.innerWidth < DIMENSIONS.SCREEN_1024
    }
);
export const IS_SCREEN_425 = new InjectionToken<boolean>(
    'Is screen smaller than 768',
    {
        providedIn: 'root',
        factory: () => window.innerWidth < DIMENSIONS.SCREEN_768
    }
);
export const IS_SCREEN_375 = new InjectionToken<boolean>(
    'Is screen smaller than 425',
    {
        providedIn: 'root',
        factory: () => window.innerWidth < DIMENSIONS.SCREEN_425
    }
);
export const IS_SCREEN_320 = new InjectionToken<boolean>(
    'Is screen smaller than 375',
    {
        providedIn: 'root',
        factory: () => window.innerWidth < DIMENSIONS.SCREEN_375
    }
);

export const CURRENT_BREAKPOINT = new InjectionToken<DIMENSIONS>(
    'Get the current breakpoint',
    {
        providedIn: 'root',
        factory: () => {
            const ranges: { min: number; max: number; id: number }[] = [
                {
                    min: 0,
                    max: DIMENSIONS.SCREEN_375,
                    id: DIMENSIONS.SCREEN_320
                },
                {
                    min: DIMENSIONS.SCREEN_375,
                    max: DIMENSIONS.SCREEN_425,
                    id: DIMENSIONS.SCREEN_375
                },
                {
                    min: DIMENSIONS.SCREEN_425,
                    max: DIMENSIONS.SCREEN_768,
                    id: DIMENSIONS.SCREEN_425
                },
                {
                    min: DIMENSIONS.SCREEN_768,
                    max: DIMENSIONS.SCREEN_1024,
                    id: DIMENSIONS.SCREEN_768
                },
                {
                    min: DIMENSIONS.SCREEN_1024,
                    max: DIMENSIONS.SCREEN_1440,
                    id: DIMENSIONS.SCREEN_1024
                },
                {
                    min: DIMENSIONS.SCREEN_1440,
                    max: Infinity,
                    id: DIMENSIONS.SCREEN_1440
                }
            ];

            return ranges.find((range) => {
                return (
                    window.innerWidth >= range.min &&
                    window.innerWidth < range.max
                );
            })!.id;
        }
    }
);
export const IS_AVIF_SUPPORTED = new InjectionToken<Observable<boolean>>(
    'IS_AVIF_SUPPORTED',
    {
        providedIn: 'root',
        factory: () =>
            new Observable<boolean>((subscriber) => {
                const img = new Image();
                img.onload = () => {
                    subscriber.next(true);
                };
                img.onerror = () => {
                    subscriber.next(false);
                };
                img.src =
                    'data:image/avif;base64,AAAAIGZ0eXBhdmlmAAAAAGF2aWZtaWYxbWlhZk1BMUIAAADybWV0YQAAAAAAAAAoaGRscgAAAAAAAAAAcGljdAAAAAAAAAAAAAAAAGxpYmF2aWYAAAAADnBpdG0AAAAAAAEAAAAeaWxvYwAAAABEAAABAAEAAAABAAABGgAAAB0AAAAoaWluZgAAAAAAAQAAABppbmZlAgAAAAABAABhdjAxQ29sb3IAAAAAamlwcnAAAABLaXBjbwAAABRpc3BlAAAAAAAAAAIAAAACAAAAEHBpeGkAAAAAAwgICAAAAAxhdjFDgQ0MAAAAABNjb2xybmNseAACAAIAAYAAAAAXaXBtYQAAAAAAAAABAAEEAQKDBAAAACVtZGF0EgAKCBgANogQEAwgMg8f8D///8WfhwB8+ErK42A=';
            }).pipe(shareReplay())
    }
);

export const LINKS: SocialLinks = {
    GOOGLE_PLAY: 'https://wishtrip.onelink.me/BNk1/18d727c9',
    GOOGLE_PLAY_MARKET:
        'https://play.google.com/store/apps/details?id=com.recntrek',
    APPSTORE: 'https://wishtrip.onelink.me/wvet/92cbf6f4',
    APPSTORE_MARKET:
        'https://apps.apple.com/us/app/wishtrip-travel-album-creator/id1162821098',
    YOUTUBE:
        'https://www.youtube.com/playlist?list=PLlqtDd_ru9iB1y7i4GYBu6edhfInL5sYO',
    FACEBOOK: 'https://www.facebook.com/WishTrip-146988566066509',
    TWITTER: 'https://twitter.com/wishtrip',
    INSTAGRAM: 'https://www.instagram.com/wishtrip/',
    LINKEDIN: 'https://il.linkedin.com/company/wishtrip'
};

export const MEDIA_TYPES: MediaTypes = {
    VIDEO: '900900000090001',
    IMAGE: '900900000090002',
    AUDIO: '900900000090003'
};

export const TREK_AREAS: TrekAreas = {
    NATURE: '900900000040001',
    URBAN: '900900000040002'
};

export const TREK_AREA_CLASSES: { [name: string]: string } = {
    [TREK_AREAS.NATURE]: 'icon-nature',
    [TREK_AREAS.URBAN]: 'icon-urban'
};

export const TREK_SEGMENTS: TrekSegments = {
    WALKING: '900900000010001',
    BICYCLE: '900900000010002',
    CAR: '900900000010004'
};

export enum AREA_CARD_TYPE {
    TOURING = 'touring',
    SITE = 'site',
    USER = 'user',
    TREK = 'trek',
    ITINERARY = 'itinerary'
}

export const TAG_CATEGORY_ID: TagCategoriesIds = {
    season: '900900000200009',
    generalInfo: '900900000200012',
    friendliness: '900900000200010'
};

export interface EnumTrekPrivacy {
    PUBLIC: number;
    MEDIA_PRIVATE: number;
    PRIVATE: number;
}

export const ENUM_TREK_PRIVACY: EnumTrekPrivacy = {
    PUBLIC: 2,
    MEDIA_PRIVATE: 3,
    PRIVATE: 4
};

export const ROUTING_ERRORS: RouteErrors = {
    NOT_FOUND: 'THIS_PAGE_DOES_NOT_EXIST',
    LOGIN_REQUIRED: 'PLEASE_LOGIN_TO_VIEW_PAGE',
    TREK_BEING_PROCESSED: 'TREK_IS_STILL_BEING_PROCESSED'
};

export const TREK_MEDIA_TYPES_CODES = {
    VIDEO: 900900000090001,
    PICTURE: 900900000090002,
    AUDIO: 900900000090003,
    TREK_VIDEO: 900900000090005
};

export const TREK_PATH_TYPE = {
    TREK: 'trek',
    TREK_ROUTES: 'trek_routes',
    DYNAMIC: 'dynamic',
    ITINERARY_TREK: 'itinerary_trek'
};

export const DEFAULT_OOI_CATEGORY = 'NOT_CATEGORIZED';

export enum Currency {
    USD = 'USD',
    EUR = 'EUR'
}

export const CURRENCY_SYMBOLS: Record<Currency, string> = {
    USD: '$',
    EUR: '€'
};

export const PENDING_ACTIONS = {
    DELETE: 'delete',
    CONFIRM: 'confirm'
};

export const getClientsSites = () => [
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/CTP.jpg`,
        link: 'https://www.wishtrip.com/site/100520000101787'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/route66.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108756'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Gettysburg.jpg`,
        link: 'https://www.wishtrip.com/site/100520000103008'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Discoverseneca.jpg`,
        link: 'https://www.wishtrip.com/site/100520000103487'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/KKL.jpg`,
        link: 'https://www.wishtrip.com/organization/100100000384984'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Menashe.jpg`,
        link: 'https://www.wishtrip.com/site/100520000100156'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/OldForge.jpg`,
        link: 'https://www.wishtrip.com/site/100520000102368'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Patterson.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108893'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Ceazaria.jpg`,
        link: 'https://www.wishtrip.com/site/100520000100235'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Lakeside-Eco-Sports.jpg`,
        link: 'https://www.wishtrip.com/site/100520000101866'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Springfeild.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108755'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/KfarKama.jpg`,
        link: 'https://www.wishtrip.com/site/100520000100158'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Tenaka.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108951'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/RamatHanadiv.jpg`,
        link: 'https://www.wishtrip.com/site/100520000100193'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Avon.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108867'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/RPPN.jpg`,
        link: 'https://www.wishtrip.com/site/100520000100239'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Meckley.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108923'
    },
    {
        logo: `${environment.ASSETS_BASE_URL}/assets/images/visiting-sites/sites/Richardson.jpg`,
        link: 'https://www.wishtrip.com/site/100520000108861'
    }
];

export interface LinkAndTarget {
    url: string;
    target: '_blank' | '_self';
}

export const getWtTosUrl = (
    lang: LocaleType | string = 'en'
): LinkAndTarget => {
    if (lang === 'en') {
        return {
            url: `${environment.B2C_WEBSITE_URL}/terms_of_service.html`,
            target: '_self'
        };
    }
    return {
        url: `https://www-wishtrip-com.translate.goog/terms_of_service.html?_x_tr_sl=en&_x_tr_tl=${lang}&_x_tr_hl=${lang}&_x_tr_pto=wapp`,
        target: '_blank'
    };
};
export const getWtPrivacyPolicyUrl = (
    lang: LocaleType | string = 'en'
): LinkAndTarget => {
    if (lang === 'en') {
        return {
            url: `${environment.B2C_WEBSITE_URL}/privacy_policy.html`,
            target: '_self'
        };
    }
    return {
        url: `https://www-wishtrip-com.translate.goog/privacy_policy.html?_x_tr_sl=en&_x_tr_tl=${lang}&_x_tr_hl=${lang}&_x_tr_pto=wapp`,
        target: '_blank'
    };
};
export const getWtCookiesPolicyUrl = (
    lang: LocaleType | string = 'en'
): LinkAndTarget => {
    if (lang === 'en') {
        return {
            url: `${environment.B2C_WEBSITE_URL}/cookies_policy.html`,
            target: '_self'
        };
    }
    return {
        url: `https://www-wishtrip-com.translate.goog/cookies_policy .html?_x_tr_sl=en&_x_tr_tl=${lang}&_x_tr_hl=${lang}&_x_tr_pto=wapp`,
        target: '_blank'
    };
};

export function getContentTypeByFile(fileName: string) {
    let rc = 'application/octet-stream';
    const fn = fileName.toLowerCase();

    if (fn.indexOf('.html') >= 0) {
        rc = 'text/html';
    } else if (fn.indexOf('.css') >= 0) {
        rc = 'text/css';
    } else if (fn.indexOf('.json') >= 0) {
        rc = 'application/json';
    } else if (fn.indexOf('.js') >= 0) {
        rc = 'application/x-javascript';
    } else if (fn.indexOf('.png') >= 0) {
        rc = 'image/png';
    } else if (fn.indexOf('.jpg') >= 0) {
        rc = 'image/jpeg';
    } else if (fn.indexOf('.jpeg') >= 0) {
        rc = 'image/jpeg';
    } else if (fn.indexOf('.svg') >= 0) {
        rc = 'image/svg+xml';
    } else if (fn.indexOf('.mp4') >= 0) {
        rc = 'video/mp4';
    } else if (fn.indexOf('.avi') >= 0) {
        rc = 'video/x-msvideo';
    } else if (fn.indexOf('.mpg') >= 0) {
        rc = 'video/mpeg';
    } else if (fn.indexOf('.mpeg') >= 0) {
        rc = 'video/mpeg';
    }

    return rc;
}

export function getFileExtension(fileName: string): string {
    let stringWithoutParams = fileName;
    if (fileName.lastIndexOf('?') !== -1) {
        stringWithoutParams = fileName.substring(0, fileName.lastIndexOf('?'));
    }
    return stringWithoutParams.substring(
        stringWithoutParams.lastIndexOf('.') + 1
    );
}

export function getUserProfilePicUrl(userId: string): string {
    return `${environment.MEDIA_URL}/profile_picture/${userId}/small`;
}

const VIDEO = ['mp4', 'avi', 'mpeg'];
const AUDIO = ['mp3', 'aac', 'm4a'];
const IMAGE = ['png', 'jpg', 'jpeg'];

export function getMediaType(fileExt: string): number | undefined {
    if (IMAGE.indexOf(fileExt) > -1) {
        return TREK_MEDIA_TYPES_CODES.PICTURE;
    } else if (VIDEO.indexOf(fileExt) > -1) {
        return TREK_MEDIA_TYPES_CODES.VIDEO;
    } else if (AUDIO.indexOf(fileExt) > -1) {
        return TREK_MEDIA_TYPES_CODES.AUDIO;
    } else {
        return undefined;
    }
}

export const SUPPORTED_VIDEO_FILES = ['.avi', '.mp4', '.mov', '.mkv', '.mpeg4'];

export const APP_SMART_LINK = 'https://wishtrip.page.link/app';
export const KKL_SMART_LINK = 'https://wishtrip.page.link/kkl_app';
export const KKL_QUERY_PARAM_KEY = 'store-path';
export const KKL_QUERY_PARAM_VALUE = 'kkl_app';
